<template>
  <div class="text-start m-3">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div class="second-menu d-flex justify-content-between align-items-center">
      <div class="status-item d-flex ">
        <div
          class="status-all"
          :class="active == 'all' ? 'status-active' : ''"
          @click="changeActive('all')"
        >
          {{ $t("maintenance_report.all") }} ({{ count.all }})
        </div>
        <div
          class="status"
          :class="active == 'pending' ? 'status-active' : ''"
          @click="changeActive('pending')"
        >
          {{ $t("maintenance_report.pending") }}
          ({{ count.pending }})
        </div>
        <div
          class="status"
          :class="active == 'in_progress' ? 'status-active' : ''"
          @click="changeActive('in_progress')"
        >
          {{ $t("maintenance_report.in_progress") }}

          ({{ count.in_progress }})
        </div>
        <div
          class="status"
          :class="active == 'complete' ? 'status-active' : ''"
          @click="changeActive('complete')"
        >
          {{ $t("maintenance_report.complete") }}
          ({{ count.complete }})
        </div>
        <div
          class="status"
          :class="active == 'cancel' ? 'status-active' : ''"
          @click="changeActive('cancel')"
        >
          {{ $t("maintenance_report.cancel") }}

          ({{ count.cancel }})
        </div>
        <div
          class="status"
          :class="active == 'reject' ? 'status-active' : ''"
          @click="changeActive('reject')"
        >
          {{ $t("maintenance_report.reject") }}
          ({{ count.reject }})
        </div>
      </div>
      <!-- scan addreport filter -->
      <div class=" d-flex align-items-center flex-wrap justify-content-end">
        <button class="scan mx-1 flex-shrink-0" type="button" @click="scanQr()">
          <img src="@/assets/scan.svg" />
        </button>
        <!-- click to add maintenance request -->
        <button
          @click="toShowMaintenanceReport"
          class="add-report mx-1 flex-shrink-0"
          type="button"
        >
          <img src="@/assets/add-doc.svg" />
        </button>
        <button
          @click="downloadReport()"
          class="add-report mx-1 flex-shrink-0"
          type="button"
        >
          <img src="@/assets/Maintenancing/download-all2.svg" />
        </button>
        <div class="position-relative m-2" style="height: fit-content">
          <input
            v-debounce:700ms="getMaintenanceReportData"
            v-model="searchText"
            type="text"
            :placeholder="$t('maintenance_report.search')"
            class="form-control"
            style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important; border-radius: 11px; border: none; height: 45px; width: 200px"
          />
          <img
            class="position-absolute top-50 translate-middle-y"
            style="left: 5px"
            src="../../assets/search-white.svg"
            width="20px"
          />
        </div>
        <!-- filter -->
        <button
          class="filter flex-shrink-0"
          type="button"
          id="button-addon2"
          @click="$bvModal.show('filter-modal')"
        >
          <img src="@/assets/filter-white.svg" />
        </button>
        <b-modal
          id="filter-modal"
          @cancel="handleclear"
          :cancel-title="$t('btn.clear')"
          hide-header-close
          hide-header
          :ok-title="$t('btn.conf')"
          @ok="handleFilter"
        >
          <b-row>
            <b-col class="text-end" cols="2"> </b-col>
            <b-col class="text-center" cols="8">
              <h3 class="text-dark">{{ $t("filter.dt_fil") }}</h3>
            </b-col>
            <b-col class="text-end" cols="2">
              <button
                class="close_modal text-light border-none"
                @click="$bvModal.hide('filter-modal')"
              >
                <b-icon-x scale="1.5" />
              </button>
            </b-col>
          </b-row>

          <label class="mt-2">{{ $t("home.cat") }}</label>
          <v-select
            :options="optionCategory"
            label="label"
            :reduce="(option) => option.value"
            v-model="filterOptions.category"
            class="dropdown-style"
            :clearable="false"
          />
          <label class="mt-2">{{ $t("home.sub_cat") }}</label>
          <v-select
            :options="optionSubCategory"
            label="label"
            :reduce="(option) => option.value"
            v-model="filterOptions.sub_category"
            class="dropdown-style"
            :clearable="false"
          />
          <!-- <label class="mt-2">{{ $t("home.as_stat") }}</label>
          <v-select
            :options="statusOption"
            label="label"
            :reduce="(option) => option.value"
            v-model="filterOptions.status"
            class="dropdown-style"
            :clearable="false"
          /> -->
          <label class="mt-2">{{ $t("home.build") }}</label>
          <v-select
            :options="optionBuilding"
            label="label"
            :reduce="(option) => option.value"
            v-model="filterOptions.building"
            class="dropdown-style"
            :clearable="false"
          />
          <label class="mt-2">{{ $t("home.fl") }}</label>
          <v-select
            :options="optionFloor"
            label="label"
            :reduce="(option) => option.value"
            v-model="filterOptions.floor"
            class="dropdown-style"
            :clearable="false"
          />
          <label class="mt-2">{{ $t("home.department") }}</label>
          <v-select
            :options="optionDepartment"
            label="label"
            :reduce="(option) => option.value"
            v-model="filterOptions.department"
            class="dropdown-style"
            :clearable="false"
          />
          <label class="mt-2">{{ $t("home.zone") }}</label>
          <v-select
            :options="optionZone"
            label="label"
            :reduce="(option) => option.value"
            v-model="filterOptions.zone"
            class="dropdown-style"
            :clearable="false"
          />
          <label class="mt-2">{{ $t("maintenance_report.urgency") }}</label>
          <v-select
            :options="optionPriority"
            label="label"
            :reduce="(option) => option.value"
            v-model="filterOptions.priority"
            class="dropdown-style"
            :clearable="false"
          />
          <div class="d-flex w-100 gap-2">
            <div class="w-50">
              <label class="mt-2">{{ $t("maintenance_report.month") }}</label>
              <v-select
                :options="optionMonth"
                label="label"
                :reduce="(option) => option.value"
                v-model="filterOptions.month"
                class="dropdown-style"
                :clearable="false"
              />
            </div>
            <div class="w-50">
              <label class="mt-2">{{ $t("maintenance_report.year") }}</label>
              <select
                class="form-control form-border"
                v-model="filterOptions.year"
              >
                <option
                  v-for="(stat, index) in yearOptions"
                  :key="index"
                  :value="stat.value"
                >
                  {{ stat.text }}
                </option>
              </select>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <MyMaintenanceReportListTable
      :maintenanceRole="maintenanceRole"
      @change-status="onChangeStatus"
      @manage-selected-table="onManageChangeTable"
      @find-form-id="onFindFormId"
      :tableData="tableData"
    ></MyMaintenanceReportListTable>
    <div class="d-flex justify-content-center mt-1">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
      />
    </div>
    <!-- manageSelected selectedOption, id -->
    <MaintenanceReport
      :maintenanceRole="maintenanceRole"
      :assetData="assetData"
      ref="maintenanceReport"
      @close-report-data="closeReportData()"
      @reload-table="reloadTable()"
    ></MaintenanceReport>
    <!--manageSelected selectedOption, id -->
    <ConfirmDialog
      :showModal="showModal"
      :manageFromTable="manageSelected"
      :fromId="findFormId"
      @hide-modal="closeModal()"
      @hide-modal-success="closeModalSuccess()"
      @manage-request="manageRequest"
      :technicianData="technicianData"
    />
    <BaseDialog
      :showModal="showModalBase"
      :errorMessage="Dialog"
      :showCloseButton="true"
      :hideButton="true"
      @hide-modal="hideModalBase()"
    >
      <div v-click-outside="hideModalBase">
        <div class="modal-dialog" v-if="Dialog.show == 'error'">
          <div class="text-center pb-2">
            <img src="@/assets/cancle.svg" alt="error" width="35px" />
          </div>
          <p class="text-center">Request fail : {{ Dialog.message }}</p>
        </div>

        <div class="modal-dialog" v-else>
          <div class="text-center pb-2">
            <img
              src="@/assets/check_confirm.svg"
              width="40px"
              alt="success"
              class="m-3"
            />
          </div>
          <p class="text-center">{{ Dialog.message }}</p>
        </div>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { featureFlags } from "../../native-app-feature-flag";
import MyMaintenanceReportListTable from "../../components/Table/Maintenance/MyMaintenanceReportListTable.vue";
import MaintenanceReport from "../../components/Maintenance/MaintenanceReport.vue";
import ConfirmDialog from "@/components/MaintenanceConfirmDialog.vue";
import { authHeader } from "@/store/actions";
import baseUrl from "@/util/backend";
import axios from "axios";
import { mapGetters } from "vuex";
import BaseDialog from "@/components/BaseDialog.vue";
import Loading from "vue-loading-overlay";
import router from "@/router";

export default {
  components: {
    Loading,
    BaseDialog,
    MyMaintenanceReportListTable,
    MaintenanceReport,
    ConfirmDialog,
  },
  data() {
    return {
      pageName: "",
      technicianData: [],
      isLoading: false,
      tableData: [],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      count: {},
      countItem: 0,
      manageRequestFromTable: "",
      showModalBase: false,
      Dialog: { show: null, message: "" },
      showModal: false,
      manageSelected: {},
      findFormId: {},
      searchText: "",
      active: "all",
      assetId: this.$route.params.assetId,
      assetData: null,
      id_company: "",
      categoryOptions: [{ value: null, text: this.$t("filter.all") }],
      monthOptions: [
        { name: this.$t("maintenance_report.january"), value: "1" },
        { name: this.$t("maintenance_report.february"), value: "2" },
        { name: this.$t("maintenance_report.march"), value: "3" },
        { name: this.$t("maintenance_report.april"), value: "4" },
        { name: this.$t("maintenance_report.f-may"), value: "5" },
        { name: this.$t("maintenance_report.june"), value: "6" },
        { name: this.$t("maintenance_report.july"), value: "7" },
        { name: this.$t("maintenance_report.august"), value: "8" },
        { name: this.$t("maintenance_report.september"), value: "9" },
        { name: this.$t("maintenance_report.october"), value: "10" },
        { name: this.$t("maintenance_report.november"), value: "11" },
        { name: this.$t("maintenance_report.december"), value: "12" },
      ],
      yearOptions: [],
      priorityData: [
        { name: this.$t("maintenance_report.high"), value: "high" },
        { name: this.$t("maintenance_report.medium"), value: "medium" },
        { name: this.$t("maintenance_report.low"), value: "low" },
      ],
      statusData: [
        { name: this.$t("count.usable"), value: "available" },
        { name: this.$t("count.not_inuse"), value: "unavailable" },
        { name: this.$t("asset.not_reg"), value: "unregister" },
        { name: this.$t("asset.not_count"), value: "uncounting" },
        { name: this.$t("count.irrepar"), value: "cannot_repair" },
        { name: this.$t("count.repairing"), value: "maintain" },
        { name: this.$t("count.broke"), value: "broke" },
        { name: this.$t("filter.lost"), value: "lost" },
      ],
      filterOptions: {
        text: "",
        category: null,
        sub_category: null,
        status: null,
        building: null,
        floor: null,
        department: null,
        zone: null,
        priority: null,
        month: null,
        year: new Date().getFullYear(),
      },
    };
  },

  async created() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    // this.$store.dispatch("maintenance/setDashboard");

    if (this.assetId) {
      await this.getAssetData(this.assetId);
    } else if (this.$route.query.maintain) {
      await this.toShowReportDetail(this.$route.query.maintain);
    }
    this.active = this.setStatusFromDashboard;
    // const thisYear = new Date().getFullYear();
    for (let i = 2023; i <= this.filterOptions.year; i++) {
      this.yearOptions.push({
        value: i,
        text: i,
      });
    }
  },
  watch: {
    currentPage() {
      this.getMaintenanceReportData();
    },
    async active() {
      this.getMaintenanceReportData();
    },
  },
  computed: {
    ...mapGetters({
      setStatusFromDashboard: "maintenance/setStatusFromDashboard",
      dashboardData: "maintenance/setDashboardData",
      statusSummary: "maintenance/statusSummary",
      maintenanceRole: "maintenance/maintenanceRole",
      permissionStatus: "permissionStatus",
      optionChoice: "getfilterOption",
      manageOptions: "maintenance/getManageOptions",
    }),
    optionCategory() {
      if (this.optionChoice.optionCategory) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionCategory.map((item) => {
              return { label: item.name, value: item.id_category };
            })
          ),
        ];
        // console.log("Building list: ", list);
        return list;
      }
      return [{ label: this.$t("filter.all"), value: null }];
    },
    optionSubCategory() {
      if (this.filterOptions.category) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionCategory
            .find(
              (category) => category.id_category === this.filterOptions.category
            )
            ?.sub_categories.map((item) => {
              return {
                label: item.name,
                value: item.id_sub_category,
              };
            }),
        ];
        return list;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionBuilding() {
      if (this.optionChoice.optionBuilding) {
        const buildingList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionBuilding.map((each) => {
            return {
              label: each.name,
              value: each.id_location_building,
            };
          }),
        ];
        // console.log("Building list: ", buildingList);
        return buildingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionFloor() {
      if (this.filterOptions.building) {
        const floorList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice?.optionFloor
              .filter((item) => {
                let building = this.optionChoice?.optionBuilding.find(
                  (building) =>
                    building.id_location_building == this.filterOptions.building
                );
                if (
                  building?.id_location_building == item.id_location_building
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .map((each) => {
                return { label: each.name, value: each.id_location_floor };
              })
          ),
        ];
        return floorList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionDepartment() {
      if (this.optionChoice.optionDepartment) {
        let departmentList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionDepartment.map((item) => {
              return {
                label: `${item.code} - ${item.name}`,
                value: item.id_location_department,
              };
            })
          ),
        ];
        // console.log("Department list: ", departmentList);
        return departmentList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionZone() {
      if (this.optionChoice.optionZone) {
        const zoneList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionZone.map((each) => {
              return {
                label: each.name,
                value: each.id_location_zone,
              };
            })
          ),
        ];
        // console.log("zone list: ", zoneList);
        return zoneList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionPriority() {
      if (this.priorityData) {
        const priorityList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.priorityData.map((each) => {
              return {
                label: each.name,
                value: each.value,
              };
            })
          ),
        ];
        // console.log(" list: ", priorityList);
        return priorityList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionMonth() {
      if (this.monthOptions) {
        const monthList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.monthOptions.map((each) => {
              return {
                label: each.name,
                value: each.value,
              };
            })
          ),
        ];
        // console.log(" list: ", priorityList);
        return monthList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    statusOption() {
      if (this.statusData) {
        const statusList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.statusData.map((each) => {
              return {
                label: each.name,
                value: each.value,
              };
            })
          ),
        ];
        // console.log(" list: ", statusList);
        return statusList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
  },
  async mounted() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.$store.dispatch("loadOptionChoice");
    this.getMaintenanceReportData();
  },
  methods: {
    reloadTable() {
      this.getMaintenanceReportData();
    },
    async downloadReport() {
      this.isLoading = true;
      try {
        if (this.$route.name == "MaintenanceReportAll") {
          this.pageName = "all";
        } else if (this.$route.name == "MyMaintenanceList") {
          this.pageName = "responsible";
        } else if (this.$route.name == "MyMaintenanceReportList") {
          this.pageName = "own";
        }
        const res = await axios.get(
          `${baseUrl()}maintain/page/${this.pageName}/status/${
            this.active
          }/excel`,
          {
            params: {
              id_company: this.id_company,
              year: this.filterOptions.year,
              month: this.filterOptions.month,
            },
            ...authHeader(),
            responseType: "blob", // set response type to blob
          }
        );
        // create URL for blob
        const url = window.URL.createObjectURL(new Blob([res.data]));
        // create link to download file
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Maintenance_report_list.xlsx");
        document.body.appendChild(link);
        link.click();
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.message) {
          alert(err.response.data.message);
        } else {
          alert(err.message || err);
        }
        console.log(err);
      }
    },
    async handleclear() {
      this.searchText = "";
      this.currentPage = 1;
      this.filterOptions = {
        text: "",
        category: null,
        sub_category: null,
        status: null,
        building: null,
        floor: null,
        department: null,
        zone: null,
        month: null,
        year: "2023",
        priority: null,
      };
      this.getMaintenanceReportData();
    },
    handleFilter() {
      this.filterOptions.text = this.searchText;
      // console.log(this.filterOptions);
      this.getMaintenanceReportData();
    },
    async getMaintenanceReportData() {
      this.isLoading = true;
      try {
        if (this.$route.name == "MaintenanceReportAll") {
          //{{baseUrl}}/maintain/all?id_company=
          const res = await axios.get(`${baseUrl()}maintain/all`, {
            params: {
              id_company: this.id_company,
              page: this.currentPage,
              limit: this.perPage,
              status: this.active == "all" ? null : this.active,
              search: this.searchText,
              month: this.filterOptions.month,
              year: this.filterOptions.year,
              priority: this.filterOptions.priority,
              department: this.filterOptions.department,
              category: this.filterOptions.category,
              sub_category: this.filterOptions.sub_category,
              building: this.filterOptions.building,
              floor: this.filterOptions.floor,
              zone: this.filterOptions.zone,
              asset_status: this.filterOptions.status,
            },
            ...authHeader(),
          });
          this.tableData = [];
          this.totalRows = res.data.message.count.all;
          if (this.active) {
            this.totalRows = res.data.message.count[this.active];
          }
          this.count = res.data.message.count;
          res.data.message.rows.forEach((item, index) =>
            this.tableData.push({
              index: index + 1 + (this.currentPage - 1) * this.perPage,
              ...item,
            })
          );
        }
        if (this.$route.name == "MyMaintenanceList") {
          //{{baseUrl}}/maintain/responsible?id_company=1
          const res = await axios.get(`${baseUrl()}maintain/responsible`, {
            params: {
              id_company: this.id_company,
              page: this.currentPage,
              limit: this.perPage,
              status: this.active == "all" ? null : this.active,
              search: this.searchText,
              month: this.filterOptions.month,
              year: this.filterOptions.year,
              priority: this.filterOptions.priority,
              department: this.filterOptions.department,
              category: this.filterOptions.category,
              sub_category: this.filterOptions.sub_category,
              building: this.filterOptions.building,
              floor: this.filterOptions.floor,
              zone: this.filterOptions.zone,
            },
            ...authHeader(),
          });
          this.totalRows = res.data.message.count.all;
          if (this.active) {
            this.totalRows = res.data.message.count[this.active];
          }
          this.count = res.data.message.count;
          this.tableData = [];
          this.countPending = 0;
          res.data.message.rows.forEach((item, index) => {
            this.tableData.push({
              index: index + 1 + (this.currentPage - 1) * this.perPage,
              ...item,
            });
          });
        }
        if (this.$route.name == "MyMaintenanceReportList") {
          //{{baseUrl}}/maintain/own?id_company=1 รายการแจ้งซ่อมของฉัน
          const res = await axios.get(`${baseUrl()}maintain/own`, {
            params: {
              id_company: this.id_company,
              page: this.currentPage,
              limit: this.perPage,
              status: this.active == "all" ? null : this.active,
              search: this.searchText,
              month: this.filterOptions.month,
              year: this.filterOptions.year,
              priority: this.filterOptions.priority,
              department: this.filterOptions.department,
              category: this.filterOptions.category,
              sub_category: this.filterOptions.sub_category,
              building: this.filterOptions.building,
              floor: this.filterOptions.floor,
              zone: this.filterOptions.zone,
            },
            ...authHeader(),
          });
          this.totalRows = res.data.message.count.all;
          if (this.active) {
            this.totalRows = res.data.message.count[this.active];
          }
          this.count = res.data.message.count;
          this.tableData = [];
          res.data.message.rows.forEach((item, index) =>
            this.tableData.push({
              index: index + 1 + (this.currentPage - 1) * this.perPage,
              ...item,
            })
          );
        }
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.message) {
          alert(err.response.data.message);
        } else {
          alert(err.message || err);
        }
        console.log(err);
      }
    },
    hideModal() {
      this.showModal = false;
      this.reloadTable();
    },
    hideModalBase() {
      this.showModalBase = false;
    },
    changeActive(value) {
      this.active = value;
      // console.log(this.active);
    },
    closeModalSuccess() {
      this.showModal = false;
      // window.location.reload();
      this.getMaintenanceReportData();
    },
    closeModal() {
      this.showModal = false;
    },
    async onChangeStatus({ oldStatus, newStatus, itemID }) {
      // console.log(oldStatus, newStatus, itemID);
      this.isLoading = true;
      try {
        const res = await axios.put(
          `${baseUrl()}maintain/${itemID}/technician`,
          {
            id_company: this.id_company,
            status: newStatus,
          },
          { headers: authHeader().headers }
        );
        // console.log(res);
        this.getMaintenanceReportData();
        // window.location.reload();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          alert(error.response.data.message);
        } else {
          alert(error.message || error);
        }
        console.log(error);
      }
      //{{baseUrl}}/maintain/:id_maintain/technician
    },
    //click manage from table cellFrameWork
    manageRequest(value) {
      this.manageRequestFromTable = value;
      // console.log(value.action);
      this.manageRequestAPI(value);
    },
    //{{baseUrl}}/maintain/:id_maintain/technician : update
    //{{baseUrl}}/maintain/:id_maintain/cancel : cancel
    //{{baseUrl}}/maintain/:id_maintain/reset : reset
    async manageRequestAPI(value) {
      this.isLoading = true;
      // console.log(value);
      try {
        if (value.action == "assign") {
          const res = await axios.put(
            `${baseUrl()}maintain/${value.assetDetail.id_maintain}/technician`,
            {
              id_company: this.id_company,
              id_technician: value.assignTo,
            },
            { headers: authHeader().headers }
          );
          // console.log(res);
        } else if (value.action === "reject") {
          const res = await axios.put(
            `${baseUrl()}maintain/${value.assetDetail.id_maintain}/technician`,
            {
              id_company: this.id_company,
              status: value.action,
              note_reject: value.reason,
            },
            { headers: authHeader().headers }
          );
        } else if (value.action === "cancel") {
          const res = await axios.put(
            `${baseUrl()}maintain/${value.assetDetail.id_maintain}/${
              value.action
            }`,
            {
              id_company: this.id_company,
              status: value.action,
              note_maintain: value.reason,
            },
            { headers: authHeader().headers }
          );
        } else if (value.action === "reset") {
          const res = await axios.put(
            `${baseUrl()}maintain/${value.assetDetail.id_maintain}/reset`,
            {
              id_company: this.id_company,
              status: value.action,
              note: value.reason,
            },
            { headers: authHeader().headers }
          );
        }
        this.Dialog.message = this.$t(
          `${
            this.manageOptions.find((option) => option.value === value.action)
              .success
          }`
        );
        this.isLoading = false;
        this.Dialog.show = "success";
        this.showModalBase = true;
        await this.getMaintenanceReportData();
      } catch (error) {
        console.log(error);
        this.Dialog.show = "error";
        this.showModalBase = true;
        this.Dialog.message = error.response.data.message;
        this.isLoading = false;
      }
    },
    toShowReportDetail(ItemID) {
      this.$refs.maintenanceReport.openReportDetail(ItemID);
    },
    toShowMaintenanceReport() {
      this.$refs.maintenanceReport.openReportData();
    },
    // handleStatusFilter(status) {
    //   this.filter.status = status;
    // },

    //emit จาก table แล้วprops ต่อไปที่ confirm dialog
    onManageChangeTable({ manageSelected }) {
      if (manageSelected.selectedOption === "download") {
        this.manageSelected = null;
      } else if (manageSelected.selectedOption === "assign") {
        this.getTechnicianData();
        this.manageSelected = manageSelected;
        // console.log(this.manageSelected);
        this.showModal = true;
      } else {
        this.manageSelected = manageSelected;
        // console.log(this.manageSelected);
        this.showModal = true;
      }
      //oprn confirm dialog
    },
    async getTechnicianData() {
      this.isLoading = true;
      try {
        const res = await axios.get(
          `${baseUrl()}company/${this.id_company}/technicianList`,
          { headers: authHeader().headers }
        );
        this.technicianData = res.data.message;
        // console.log(this.technicianData);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          alert(error.response.data.message);
        } else {
          alert(error.message || error);
        }
        console.log(error);
      }
    },
    onFindFormId({ findFormId }) {
      this.findFormId = findFormId;
      // console.log(this.findFormId);
    },

    async getAssetData(assetId) {
      this.isLoading = true;
      try {
        const res = await axios.get(
          `${baseUrl()}counting/asset/${assetId}`,
          authHeader()
        );
        this.assetData = res.data.data.asset;
        this.toShowMaintenanceReport();
        // console.log(res.data.data);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        if (err.response && err.response.data && err.response.data.message) {
          alert(err.response.data.message);
        } else {
          alert(err.message || err);
        }
        console.log(err);
        router.replace({ name: "MyMaintenanceReportList" });
      }
    },

    closeReportData() {
      if (this.assetId) {
        this.$router.replace({ name: "MyMaintenanceReportList" });
        // this.assetId = null;
        // this.getMaintenanceReportData();
      } else if (this.$route.query.maintain) {
        this.$router.replace({ name: this.$route.name });
      }
    },
    scanQr() {
      if (featureFlags.scan) {
        window.sendMessage("scan", {
          mode: "maintenanceReport",
          query: { callback: "MyMaintenanceReportList" },
        });
      } else {
        this.$router.push({
          name: "MaintenanceScanner",
          query: { callback: "MyMaintenanceReportList" },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}
#dropdown-1 {
  min-width: 200px;
  ::v-deep button {
    outline: none !important;
    background: #f4f4f4 !important;
    border: 2px solid #d2d2d2;
    border-radius: 8px;
    color: #000;
    border-radius: 8px;
  }
  p {
    margin: 0px;
    padding: 5px 0;
    font-size: 14px;
  }
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
  }
}

.scan {
  border-radius: 10px 10px 10px 10px !important;
  background: #ff8a00 !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
  }
}

.add-report {
  border-radius: 10px 10px 10px 10px !important;
  background: #007afe !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
  }
}

.status-all {
  margin: 5px 0;
  text-align: center;
  width: 170px;
  padding: 5px 15px;
}

.status-item {
  font-size: 16px;
  color: #7c7c7c;
  white-space: nowrap;
  overflow-x: auto;
}
.status-active {
  color: #007afe;
  border-bottom: 2px solid #007afe;
}
.status {
  margin: 5px 0;
  text-align: center;
  width: 170px;
  border-left: 1px solid #7c7c7c;
  padding: 5px 15px;
}
.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}
.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}
select {
  background: white;
}
.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

@media (max-width: 1600px) {
  .second-menu {
    display: block !important;
  }
}
</style>
